<template>
  <v-card>
    <v-card-text class="pa-0">
      <template>
        <v-toolbar card color="white">
          <v-text-field
            flat
            solo
            placeholder="Filtrar"
            v-model="search"
            hide-details
            class="hidden-sm-and-down"
          ></v-text-field>
          <v-btn icon @click="pesquisar">
            <v-icon>search</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="romaneios"
          :rows-per-page-items="[10, 25, 50, { text: 'All', value: -1 }]"
          class="elevation-1"
        >
          <template v-slot:items="props">
            <td>{{ props.item.id }}</td>
            <td>{{ props.item.nomePromotor }}</td>
            <td class="text-xs-left">{{ props.item.nomePraca }}</td>
            <td>{{ props.item.dataSaidaString }}</td>
            <td class="text-xs-right">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" depressed icon dark v-bind="attrs" v-on="on">
                    <v-icon small>article</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-btn title="Editar" @click="editar(props.item.id)" depressed icon dark color="primary" small>
                    <v-icon small>edit</v-icon>
                  </v-btn>
                </v-list>
              </v-menu>
            </td>
          </template>
        </v-data-table>
        <div>
          <progress-circular></progress-circular>
        </div>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
import { serverBus } from "../../main"
import { ServicoRomaneio } from "../../servicos/servicoRomaneio"
import ProgressCircular from "../progressCircular/progressCircular"
const servicoRomaneio = new ServicoRomaneio()
export default {
  components: {
    ProgressCircular
  },
  data() {
    return {
      search: "",
      romaneios: [],
      romaneiosSemFiltro: [],
      headers: [
        { text: "Código", align: "left", value: "id", sortable: false },
        { text: "Promotor", align: "left", value: "nomePromotor", sortable: false },
        { text: "Praca", value: "nomePraca", sortable: false },
        { text: "Data de saída", value: "dataSaidaString", sortable: false },
        { text: "", value: "action", align: "right", sortable: false }
      ]
    }
  },
  created() {
    servicoRomaneio.buscarRomaneios().then(
      res => {
        if (res.status === 200) {
          this.romaneios = res.data
          this.romaneiosSemFiltro = this.romaneios
        }
      },
      // eslint-disable-next-line
      err => console.log(err)
    )
  },
  methods: {
    editar(id) {
      this.$router.push({ name: "edit-romaneio-itens-nao-ficaram-em-saida", params: { id: id } })
    },
    pesquisar() {
      if (this.search === "") {
        this.romaneios = this.romaneiosSemFiltro
      } else {
        this.romaneios = this.romaneiosSemFiltro
        var result = this.romaneios.filter(obj => {
          return (
            (obj.id !== null && obj.id.toString().includes(this.search, 0)) ||
            (obj.nomePromotor !== null &&
              obj.nomePromotor
                .toString()
                .toUpperCase()
                .includes(this.search.toUpperCase(), 0)) ||
            (obj.nomePraca !== null &&
              obj.nomePraca
                .toString()
                .toUpperCase()
                .includes(this.search.toUpperCase(), 0)) ||
            (obj.dataSaidaString !== null &&
              obj.dataSaidaString
                .toString()
                .toUpperCase()
                .includes(this.search.toUpperCase(), 0)) ||
            this.ehDevolvido(obj)
          )
        })
        this.romaneios = result
      }
    },
    ehDevolvido(obj) {
      if (this.search.toUpperCase() === "DEVOLVIDO" && obj.devolvido === true) {
        return true
      } else if (this.search.toUpperCase() === "NDEVOLVIDO" && obj.devolvido === false) {
        return true
      } else {
        return false
      }
    },
    abrirProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", true)
    },
    fecharProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", false)
    }
  }
}
</script>
<style scoped>
a.nounderline:link {
  text-decoration: none;
}
</style>
